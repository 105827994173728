<template>
  <v-skeleton-loader
    :loading="isLoading"
    type="heading, article@10"
    width="100%"
  >
    <template>
      <div>
        <form ref="form">
          <!-- <FormRenderer
            :form-configuration="formData"
            v-model="formInputData"
            v-if="formData && !nextPage"
          /> -->

          <div class="error-form" v-if="errorLoadingForm">
            <span>{{ errorFormMsg }}</span>
          </div>

          <hyphen-form-render
            id="form-render"
            :details.prop="details"
            :should-verify.prop="false"
            :schema.prop="formData"
            @submit.prevent="onSubmit"
            :isSubmitting.prop="isSubmitting"
            :form-id="id"
            :hyphen-base-url="getBaseUrl()"
            v-show="formData && !nextPage && canRenderForm"
          />
          <div v-show="nextPage">
            <div
              class="d-flex align-center pt-1 pb-4"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'padding: 0px 10px'
                  : 'padding: 0px 0px'
              "
            >
              <p class="title__ptext mb-0">Select payment item</p>
              <v-spacer></v-spacer>
              <v-btn
                @click="nextPage = false"
                text
                rounded
                :large="$vuetify.breakpoint.mdAndUp"
                v-if="nextPage"
              >
                <v-icon left>mdi-chevron-left</v-icon> Back
              </v-btn>
            </div>
            <transition name="animate-down">
              <SelectPayment
                v-model="apiFormBill"
                :isEmbedded="isEmbedded"
                :bills="bills"
              />
            </transition>
          </div>
          <div
            v-if="requiresPayment && bill"
            class="invoice-table"
            :class="{ 'invoice-table--embed': isEmbedded }"
          >
            <div class="invoice-table__header">
              <img class="image" src="@/assets/cash.png" alt="" />
              <span> You will be <b> Invoiced</b> (excl.TAX)</span>
            </div>
            <div class="invoice-table__body">
              <div class="row" :class="{ 'row--embed': isEmbedded }">
                <span>Item</span><span>{{ bill.name }}</span>
              </div>
              <div class="row" :class="{ 'row--embed': isEmbedded }">
                <span>Total</span
                ><span>
                  {{
                    bill.amount ? formatCurrency(bill.amount, currency) : "N/A"
                  }}
                </span>
              </div>
              <div
                v-if="bill.description"
                class="row"
                :class="{ 'row--embed': isEmbedded }"
              >
                <span class="desc">Description</span
                ><span>{{ bill.description }} </span>
              </div>
            </div>
          </div>
          <div
            style="text-align: right"
            class="px-3 px-md-6 my-4"
            v-if="nextPage && formData"
          >
            <v-btn
              @click="finalSubmit"
              :loading="isSubmitting"
              dark
              large
              elevation="1"
              color="#19283d"
              type="submit"
            >
              Submit
            </v-btn>
          </div>
        </form>

        <!-- Payment dialog refused to work with $ref so I had to use it here and eliminate using a simple dialog component -->

        <!-- <PaymentDialog ref="showdialog" /> -->

        <v-dialog
          v-model="formDialogConfirmation"
          width="90%"
          max-width="550"
          persistent
        >
          <v-card color="#f8f7f4" class="rounded-lg">
            <v-card-title
              class="mb-5"
              style="background: #ffffff; border-radius: 8px 8px 0px 0px"
            >
              <span class="title__text">Form Confirmation</span>
              <v-spacer></v-spacer>
              <v-btn color="#19283d" icon @click="handleSubmited">
                <v-icon class="text-bolder"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <div class="d-flex flex-column justify-center align-center">
              <img
                class="pngImg"
                src="@/assets/tick-circle.png"
                alt="good tick"
              />
              <p class="modal__title py-4">Form submitted successfully</p>
            </div>
            <div class="form__id mb-2 text-center" v-if="requiresPayment">
              for order id <br />
              <b class="text-h5" style="font-weight: 700"> {{ session }} </b>
            </div>
            <div v-else class="d-flex justify-center align-center pt-4 pb-8">
              <v-btn
                outlined
                color="primary"
                class="px-4"
                background-color="white"
                @click="handleSubmited"
              >
                <v-icon>mdi-chevron-right</v-icon>
                <span>Close</span>
              </v-btn>
            </div>
            <div
              class="d-flex justify-center align-center text-center"
              v-if="requiresPayment"
            >
              <p class="payInfo text-center text-gray" style="max-width: 22rem">
                <b>NOTE:</b> Your order id is useful for tracking the invoice
                issued and sent to your email address.
              </p>
            </div>
            <div
              class="d-flex align-center justify-center"
              v-if="requiresPayment"
            >
              <!-- anchor tag link that opens the pay now modal -->
              <a
                @click="triggerPaymentModal"
                class="text-center font-weight-bold"
                style="color: #5b67ba; font-size: 1.2rem"
                >Click Here to Pay Now</a
              >
            </div>

            <div class="d-flex justify-center py-8">
              <img src="@/assets/modalLogo.png" style="height: 18px" alt="" />
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="collectPaymentDialog"
          width="90%"
          max-width=""
          fullscreen
        >
          <!-- modal container with 2 child divs and a display flex property -->
          <div class="payment-wrapper">
            <!-- first child div with 40% width on desktop but 100% on mobile-->
            <div class="payment__details px-10">
              <div class="d-flex pt-6" v-if="$vuetify.breakpoint.smAndDown">
                <v-spacer></v-spacer>
                <v-btn
                  color="#19283d"
                  icon
                  @click="collectPaymentDialog = false"
                >
                  <v-icon class="text-bolder"> mdi-close </v-icon>
                </v-btn>
              </div>
              <div v-if="showOtherPayments">
                <transition name="fade">
                  <v-btn
                    @click="goBack"
                    text
                    rounded
                    large
                    class="mt-10 justify-start"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    <v-icon left>mdi-chevron-left</v-icon> Back
                  </v-btn>
                </transition>
              </div>
              <div class="payment__details-info" v-if="submitedFormBill">
                <p class="paymentAmount">
                  {{ formatCurrency(submitedFormBill.amount, currency) }}
                </p>

                <p class="paymentDate mb-1">
                  Due
                  {{
                    new Date()
                      | moment(
                        "add",
                        `${submitedFormBill.date} days`,
                        "DD/MM/YY"
                      )
                  }}
                </p>
                <!-- TODO : add view Invoice Link -->

                <!-- <div>
                  <span class="link__btn">
                    View Invoice
                    <v-icon class="link__btn-icon">mdi-link</v-icon>
                  </span>
                </div> -->
              </div>
              <p class="invoice_number" v-if="submitedFormBill">
                INVOICE # <b>{{ invoicenumber }}</b>
              </p>
            </div>

            <!-- second child div with 60% width on desktop but 100% on mobile -->
            <div class="payment__methods d-md-flex flex-md-column">
              <div
                class="d-flex px-md-10 pt-md-10"
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                <v-spacer></v-spacer>
                <v-btn color="#19283d" icon @click="closePaymentDialog">
                  <v-icon class="text-bolder"> mdi-close </v-icon>
                </v-btn>
              </div>
              <div class="d-flex justify-center align-center flex-grow-1">
                <div class="methods__wrapper">
                  <div class="d-flex flex-column justify-center px-6">
                    <div class="help pb-md-4" v-if="!showOtherPayments">
                      How would you like to pay?
                    </div>

                    <div class="pb-8">
                      <template v-if="currency === 'USD'">
                        <PWStripe
                          :invoiceId="invoiceId"
                          v-show="!showOtherPayments"
                          ref="PWStripe"
                          style="cursor: pointer"
                        />
                      </template>

                      <PWbankTransfer
                        ref="PWbankTransfer"
                        @showDrop="togglePaymentMethods"
                        :invoicenumber="invoicenumber"
                        :email="customerEmail"
                        :payableAmount="
                          submitedFormBill ? submitedFormBill.amount : ''
                        "
                        :customerHypnId="customerHypnId"
                        :organHypnId="organHypnId"
                        style="cursor: pointer"
                        :disabled="currency === 'USD'"
                      />
                      <PWterms
                        v-show="!showOtherPayments"
                        style="cursor: pointer"
                        :disabled="currency === 'USD'"
                      />
                      <PODelivery v-show="!showOtherPayments" />
                      <Reoccuring v-show="!showOtherPayments" />
                    </div>
                  </div>
                </div>
              </div>
              <p class="fullModalfooterimg text-center px-2 pr-md-2">
                powered by
                <img
                  class="pl-4"
                  style="height: 16px"
                  src="@/assets/newLogo.png"
                />
              </p>
            </div>
          </div>
        </v-dialog>

        <AccessForm
          :id="id"
          :isEmbedded="isEmbedded"
          :mode="formMode"
          @verified="verifiedOtp($event)"
          @close="isPrivateForm = false"
          :show="isPrivateForm"
        />

        <CollectSignature
          v-model="signature"
          @done="initiateSubmit"
          :show="signatureDialog"
          @close="signatureDialog = false"
        />
      </div>
    </template>
  </v-skeleton-loader>
</template>

<script>
import axios from "axios";
import PWStripe from "../components/PWStripe.vue";
import PWbankTransfer from "../components/PWbankTransfer.vue";
import PWterms from "./PWterms.vue";
import PODelivery from "./PODelivery.vue";
import Reoccuring from "./reoccuring.vue";
import CollectSignature from "./collectSignature.vue";
import AccessForm from "./AccessForm.vue";
import currencyFilter from "@/mixins/currencyFilter";
import SelectPayment from "./selectPayment.vue";

let fingerprint = null;
const IPGeolocationAPI = require("ip-geolocation-api-javascript-sdk");
var ipgeolocationApi = new IPGeolocationAPI(
  process.env.VUE_APP_IPGEOLOCATION_API_KEY,
  false
);
ipgeolocationApi.getGeolocation(handleResponse);

function handleResponse(data) {
  fingerprint = {
    ip: data.ip,
    country: data.country,
    city: data.city,
    isp: data.isp,
    lat: data.latitude,
    lon: data.longitude,
  };
}

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
    isEmbedded: {
      default: false,
    },
  },
  components: {
    PWStripe,
    PWbankTransfer,
    PWterms,
    PODelivery,
    Reoccuring,
    CollectSignature,
    AccessForm,
    SelectPayment,
  },
  // components: { paymentDialog },
  data() {
    return {
      tab: 0,
      nextPage: false,
      isLoading: false,
      formType: "",
      showOtherPayments: false,
      errorLoadingForm: false,
      errorFormMsg: "",
      currency: "NGN",
      invoiceId: "",
      selectedPaymentType: null,
      emailPattern: false,
      fieldRequired: false,
      isPrivateForm: false,
      email: null,
      bills: null,
      canRenderForm: false,

      submitedFormBill: null,
      invoicenumber: "",
      customerEmail: "",
      // walletId: "",
      customerHypnId: "",
      organHypnId: "",
      // accountNumber: "",
      // bankName: "",

      entry_id: null,
      formDialogConfirmation: false,
      collectPaymentDialog: false,
      isSubmitting: false,
      canSubmit: true,
      signatureDialog: false,
      signature: null,
      reloadForm: false,
      has_signature: null,
      savingEntries: false,
      requiresPayment: false,
      hasFile: false,
      formTitle: "Form Name Here",
      formData: null,
      formInputData: null,
      formId: null,

      formActionURL: "",
      session: null,
      fingerprint: null,
      apiFormBill: [],

      // new render demo data props
      details: {
        name: "Form Name",
        description: "Form Description",
      },
      formMode: {
        demo: false,
        user: "",
      },
    };
  },
  mixins: [currencyFilter],

  methods: {
    // trigger payment modal
    triggerPaymentModal() {
      this.formDialogConfirmation = false;
      this.collectPaymentDialog = true;
    },

    //emited event method to show the other payment methods
    togglePaymentMethods(value) {
      if (value) {
        this.showOtherPayments = !this.showOtherPayments;
      } else {
        this.showOtherPayments = false;
      }
    },

    // use $refs to access the child component
    goBack() {
      this.$refs.PWbankTransfer.goBack();
    },

    async initiateSubmit() {
      this.isSubmitting = true;

      /////////////////////////////
      const payload = {
        ...this.createEntriesData,
        fingerprint: {
          ...fingerprint,
          session: this.session,
        },
      };

      // console.log(JSON.stringify(payload, null, 2));
      try {
        let response = null;

        // await axios({
        //   method: "POST",
        //   url: formUrl,
        //   data: payload,
        // });

        if (this.$route.query.session) {
          response = await axios.post(this.formActionURL, payload, {
            headers: {
              "x-api-key": this.$route.query.session,
            },
          });
        } else {
          response = await axios.post(this.formActionURL, payload);
        }

        if (response.status === 200) {
          this.submitedFormBill = response?.data?.bill;
          this.invoicenumber = response?.data?.bill?.invoicenumber;
          this.customerEmail = response?.data?.data?.email;
          this.customerHypnId = response?.data?.bill?.customer?.hypn_id;
          this.organHypnId = response?.data?.bill?.organization;
          this.formDialogConfirmation = true;
          this.invoiceId = response?.data?.bill?.hypn_id;
          this.$emit("closeSDKForm");

          // this.getCustomerBankDetails();
        }

        //  trigger this.dialog
      } catch (error) {
        this.$notify({
          group: "info",
          type: "error",
          title: "Error",
          text:
            error?.response?.data?.message ||
            "An error occured, please try again",
        });
        if (process.env.NODE_ENV !== "development") {
          console.log(error);
        }
      } finally {
        // this.reloadForm = true;

        this.isSubmitting = false;
      }
    },

    handleSubmited() {
      this.formDialogConfirmation = false;

      if (this.isEmbedded) {
        this.$emit("endSDK");
      } else this.$router.replace("/");
    },

    async onSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      if (process.env.NODE_ENV !== "development") {
        console.log(JSON.stringify(e.detail[0], null, 2));
      }

      this.formInputData = e.detail[0];

      if (this.formType === "api" && !this.nextPage) {
        this.nextPage = true;
      } else {
        // form has signature
        if (this.has_signature) {
          this.signatureDialog = true;
        } else {
          this.initiateSubmit();
        }
      }
    },

    finalSubmit(e) {
      if (e) {
        e.preventDefault();
      }

      if (this.has_signature) {
        this.signatureDialog = true;
      } else {
        this.initiateSubmit();
      }
    },

    lookForEmailField(email) {
      if (
        this.formData &&
        typeof this.formData === "object" &&
        !Array.isArray(this.formData)
      ) {
        this.formData.properties.map((field, index) => {
          if (field.key === "email") {
            this.formData.properties[index].value = email;
          }
        });
      }

      this.canRenderForm = true;
    },

    validateEmail(value) {
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(value);
    },

    verifiedOtp({ response, email }) {
      this.$emit("openSDKForm");
      this.isPrivateForm = false;
      this.Data = response.data;
      this.requiresPayment = response.data.data.is_payment;
      this.has_signature = response.data.data.has_signature;
      this.formData = response.data.data.form_fields;
      this.session = response.data.data.session;
      this.email = email;
      this.bills = response.data.data.bill;
      this.formType = response.data.data.form_type;
      this.currency = response.data.data.currency;

      this.lookForEmailField(email);

      this.$emit("formDetails", {
        Data: this.Data,
        formData: this.formData,
        logo: response.data.data.logo,
        formDescription: response.data.data.form_description,
        qrCode: response.data.data.qrcode,
        formName: response.data.data.form_title,
        organization: response.data.data.organization,
      });
    },

    getBaseUrl() {
      return process.env.VUE_APP_BASEURL;
    },

    async fetchFormDetails(id) {
      let response = null;
      try {
        this.isLoading = true;
        if (this.$route.query.session) {
          response = await axios.get(
            process.env.VUE_APP_BASEURL + `organizations/form/${id}`,
            {
              headers: {
                "x-api-key": this.$route.query.session,
              },
            }
          );
        } else {
          response = await axios.get(
            process.env.VUE_APP_BASEURL + `organizations/form/${id}`
          );
        }

        if (response?.data?.data?.mode?.demo) {
          this.formMode = response?.data?.data?.mode;
          this.$emit("closeSDKForm");
          this.isPrivateForm = true;

          return;
        }

        this.Data = response.data;
        this.requiresPayment = response.data.data.is_payment;
        this.has_signature = response.data.data.has_signature;
        this.formData = response.data.data.form_fields;
        this.currency = response.data.data.currency;

        // added the new form render integrations here
        this.details.name = response.data.data.form_title;
        this.details.description = response.data.data.form_description;
        this.formActionURL = response.data.formActionUrl;
        // added the new form render integrations and it ends here

        this.session = response.data.data.session;
        this.bills = response.data.data.bill;
        this.formType = response.data.data.form_type;
        this.canRenderForm = true;

        this.$emit("formDetails", {
          Data: this.Data,
          formData: this.formData,
          logo: response.data.data.logo,
          formDescription: response.data.data.form_description,
          qrCode: response.data.data.qrcode,
          formName: response.data.data.form_title,
          organization: response.data.data.organization,
        });
      } catch (error) {
        if (process.env.NODE_ENV !== "development") {
          console.log(error);
        }
        if (error?.response?.status === 400) {
          // this.errMsg = error.response.data.message;
          // this.showError = true;

          if (this.isEmbedded) {
            this.$emit("formNotFound");
            this.errorLoadingForm = true;
            this.errorFormMsg = "Form Not Found";
          } else {
            this.$router.push({ name: "formNotFound" });
          }
        }

        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message ==
            "Private form: Email required for OTP"
        ) {
          this.$emit("closeSDKForm");
          this.isPrivateForm = true;
        }

        if (error?.response?.status !== 401) {
          this.$notify({
            group: "info",
            type: "error",
            title: "Error",
            text: error?.response?.data?.message || "Something went wrong!",
          });
        }
      } finally {
        this.isLoading = false;
      }
    },

    closePaymentDialog() {
      // this.fetchFormDetails(this.formId);
      this.collectPaymentDialog = false;

      if (this.isEmbedded) {
        this.$emit("endSDK");
      } else this.$router.replace("/");
    },
  },
  computed: {
    // add the payables data to the formInputData
    paymentFormInputData() {
      return {
        ...this.formInputData,
        payment_data: this.apiFormBill,
      };
    },

    //return full object to send to
    createEntriesData() {
      const has_otp = Object.keys(this.formInputData).includes(
        "verification_auth_code"
      );

      if (this.formType === "api") {
        return {
          form: this.Data.id,
          hash: "erttus",
          organization: this.Data.data.organization.id,
          has_file: this.hasFile,
          has_otp,
          form_entry: {
            ...this.paymentFormInputData,
            ...(this.email && {
              email: this.email,
            }),
          },
        };
      } else {
        if (this.has_signature) {
          return {
            form: this.Data.id,
            hash: "erttus",
            organization: this.Data.data.organization.id,
            has_file: this.hasFile,
            has_otp,
            form_entry: {
              ...this.formInputData,
              ...(this.email && {
                email: this.email,
              }),
            },
            signature: this.signature,
          };
        } else {
          return {
            form: this.Data.id,
            hash: "erttus",
            organization: this.Data.data.organization.id,
            has_file: this.hasFile,
            has_otp,
            form_entry: {
              ...this.formInputData,
              ...(this.email && {
                email: this.email,
              }),
            },
          };
        }
      }
    },

    bill() {
      if (this.formType === "api") {
        if (this.apiFormBill.length > 0) {
          let amount = 0;

          this.apiFormBill.map((bill) => (amount += parseFloat(bill.amount)));
          return {
            name: this.apiFormBill.map((bill) => bill.name).join(", "),
            // amount: amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), // add comma to amount
            amount: amount,
          };
        } else return null;
      }

      if (this.formData && this.bills && this.bills.length > 0) {
        return this.bills[this.bills.length - 1];
      } else return null;
    },

    // get due date from today using number of days
    getDueDate() {
      var today = new Date();
      var numOfDays = parseInt(this.bills[0].due_date);
      var date = new Date(today.getTime() + numOfDays * 24 * 60 * 60 * 1000);

      var day = date.getDate();
      var month = date.getMonth() + 1; // getMonth() returns a 0-based value, so we need to add 1
      var year = date.getFullYear();

      return day + "/" + month + "/" + year;
    },
  },

  watch: {
    isLoading: {
      handler(val) {
        this.$emit("isLoading", val);
      },
      immediate: true,
    },
    id: {
      async handler(val) {
        if (val) {
          await this.fetchFormDetails(val);
        }
      },
      immediate: true,
    },

    // session: {
    //   handler(val) {
    //     if (val) {
    //       this.getClientDetails();
    //     }
    //   },
    //   immediate: true,
    // },
    isPrivateForm(val) {
      setTimeout(() => {
        this.$emit("pinSDkForm", val);
      }, 1000);
    },

    formDialogConfirmation(val) {
      setTimeout(() => {
        this.$emit("pinSDkForm", val);
      }, 1000);
    },

    collectPaymentDialog(val) {
      setTimeout(() => {
        this.$emit("pinSDkForm", val);
      }, 1000);
    },

    signatureDialog(val) {
      setTimeout(() => {
        this.$emit("pinSDkForm", val);
      }, 1000);
    },

    reloadForm(val) {
      if (val) {
        this.fetchFormDetails(this.id);
      }
    },

    formData: {
      handler(val) {
        if (process.env.NODE_ENV !== "development") {
          console.log("form data", JSON.stringify(val, null, 2));
        }

        const flatStructure = [];
        val.map((item) => {
          if (item.type === "group" && item.properties.length > 0) {
            item.properties.map((i) => {
              flatStructure.push(i);
            });
          }
        });

        const hasFile = flatStructure.find((prop) => prop.type === "file");
        if (hasFile) {
          this.hasFile = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/bootstrap.min.css";

.form-wrapper {
  margin: 0 auto;
}

.error-form {
  font-size: 30px;
  font-weight: 600;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control ::placeholder {
  color: #121212 !important;
}

.formbase {
  padding-bottom: 55px;
}

.control-view-wrapper .form-control.md-field,
.field {
  height: 46px !important;
}

.footerimg {
  margin: 4em 0;
  text-align: center;
}

.v-btn--outlined {
  border: thin solid currentColor;
  background-color: #fefcf8;
}

.card-heading-section {
  height: 80px;
  margin: 0;
  width: 100%;
  background: #fdfaf2;
  box-shadow: 0px 0px 10px rgba(127, 145, 155, 0.25);
  border-radius: 8px 8px 0px 0px;
}

.modal__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #19283d;
  max-width: 217px;
}

.title__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #19283d;
}

.form__id {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: lowercase;
  color: #121212;
}

.vue-form-renderer .radio-checkbox.line label {
  display: block !important;
  padding: 10px !important;
  border: 1px solid #f4f5fa !important;
  background-color: #fafbfd !important;
  margin-bottom: 4px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  height: 56px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.headline-block p {
  display: none !important;
}

/* this styles are for  the signature pad */

#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.box {
  width: "100%";
  padding: 8px 16px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}

.tab-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #19283d;
}

.logo-container {
  width: 250px;
}

@media only screen and (max-width: 600px) {
  .container-fluid {
    padding-right: 0%;
    padding-left: 0%;
  }

  .logo-container {
    width: 95%;
  }

  .full-width {
    width: 100%;
  }
}

.upper {
  top: 0px;
}

.lower {
  bottom: 0px;
}

vue-form-builder .radio-checkbox.line label,
.vue-form-renderer .radio-checkbox.line label {
  display: block;
  padding: 10px;
  border: 1px solid #f4f5fa;
  background-color: #fafbfd;
  margin-bottom: 4px;
}

.form-control {
  border: 1px solid #d1d3dd;
}

.pngImg {
  height: 130px;
  width: 130px;
}

.amt-due {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #19283d;
}

.help {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}

@media only screen and (max-width: 768px) {
  .border {
    border: 1px solid #f6f3ee;
  }
}

@media only screen and (min-width: 768px) {
  .border {
    border: transparent;
  }
}

.invoice-table {
  width: 100%;
  // max-width: 598px;

  // @media only screen and (max-width: 500px) {
  //   max-width: 93%;
  // }

  // &--embed {
  //   max-width: 300px;
  // }

  margin: 20px auto;

  background: #ffffff;
  border: 0.859122px solid rgba(25, 40, 61, 0.1);
  border-radius: 3.43649px;

  &__header {
    padding: 15px 25px;
    border-bottom: 0.859122px solid rgba(25, 40, 61, 0.1);
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    /* or 196% */

    display: flex;
    align-content: center;
    gap: 10px;

    /* menu txt */

    color: #525e6e;

    .image {
      width: 27px;
      object-fit: fill;
      object-position: center;

      &F {
        width: 50px;
        object-fit: fill;
      }

      @media only screen and (max-width: 500px) {
        width: 30px;
      }
    }
  }

  &__body {
    padding: 15px;

    .row {
      display: flex;
      font-family: "Inter";
      font-style: normal;
      flex-direction: row;
      font-weight: 400;
      line-height: 27px;
      gap: 10px;
      /* identical to box height, or 229% */

      display: flex;

      color: rgba(25, 40, 61, 0.8);
      padding: 5px 0;

      :first-child {
        width: 30%;
      }

      @media only screen and (max-width: 500px) {
        flex-direction: column;
        gap: 2px;
        border-bottom: 0.859122px solid rgba(25, 40, 61, 0.1);

        :first-child {
          width: 100%;
        }
      }

      :last-child {
        font-weight: 700;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .desc {
        overflow: auto;
        white-space: wrap;
      }

      &--embed {
        flex-direction: column;
        gap: 2px;
        border-bottom: 0.859122px solid rgba(25, 40, 61, 0.1);

        :last-child {
          overflow: unset;
          text-overflow: unset;
          white-space: unset;
        }

        :first-child {
          width: unset;
        }
      }

      &--embed:last-child {
        border-bottom: 0;
      }
    }

    .row:last-child {
      border-bottom: 0;
    }
  }
}

.payment-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    border-bottom: 0.859122px solid rgba(25, 40, 61, 0.1);
  }
}

.payment__details {
  position: relative;
  width: 25%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #f4f5fa;
  background-color: #ffffff;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    width: 100%;
    height: unset;
  }
}

// .payment__details--embed {
//   width: 100%;
//   height: unset;
//   border: 0;
//   background-color: transparent;
// }

.payment__methods {
  width: 75%;
  height: 100vh;
  padding: 10px;
  background: #f8f7f4;

  @media only screen and (max-width: 500px) {
    padding-top: 7vh;
    flex-direction: column;

    width: 100%;
    height: 100vh;
  }
}

.payment__details-info {
  margin-top: 20vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  // align-items: center;

  @media only screen and (max-width: 500px) {
    margin-top: 0;
    padding-bottom: 7vh;
  }

  .invoice_number {
    padding-bottom: 10%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: rgba(25, 40, 61, 0.8);
    letter-spacing: 0.01em;
  }

  .paymentAmount {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    color: #19283d;
  }

  .paymentDate {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(25, 40, 61, 0.8);
  }
}

.link__btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* blue-link */

  color: #5b67ba;
  cursor: pointer;
}

.link__btn-icon {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5b67ba;
}

.methods__wrapper {
  width: 464px;
}

.fullModalfooterimg {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.636364px;

  /* menu-not-active-text */

  color: #8f96a1;
}

/* For mobile phones: */

@media only screen and (min-width: 600px) {
  /* For tablets: */
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
}

.payInfo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: lowercase;
  color: #596a73;
}

.title__ptext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  color: #000000;
  // margin-top: 1em;
  // margin-bottom: 2em;

  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
}

.animate-down {
  &enter {
    transform: translateY(-10px);
    opacity: 0;
  }

  &enter-active,
  &leave-active {
    transition: all 0.3s ease-out;
  }

  &leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
</style>
